import React from 'react';
import { Modal } from 'react-bootstrap';
import './Invoice.css';

// Enhanced function to capitalize the first letter of each word
const capitalizeWords = (str) => {
  return str
    .toLowerCase() // Convert the entire string to lowercase first
    .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
};

const Invoice = ({ invoice, onClose }) => {
  if (!invoice) return null; // Guard against rendering if invoice is undefined

  // Destructure invoice object and handle defaults if fields are missing
  const {
    customerName = '',
    customerAddress = '',
    customerCity = '',
    customerCountry = '',
    customerPostal = '',
    invoiceNumber = '',
    date = '',
    dueDate = '',
    totalAmount = 0,
    items = [],
    notes = '',
    taxRate = 0,
    tax = 0,
    subTotal = 0,
  } = invoice;

  const handlePrint = () => {
    const printContents = document.getElementById('invoice-to-print').innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // Reload to re-render the original content
  };

  return (
    <Modal show={true} onHide={onClose} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Invoice Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="invoice-to-print" className="invoice-container">
          <div className="invoice-header">
            <div className="company-details">
              <h1>Your Company Name</h1>
              <p>Your Business Address</p>
              <p>City, Country, Postal</p>
            </div>
            <div className="bill-to">
              <h4>Bill To:</h4>
              <p>{capitalizeWords(customerName)}</p>
              <p>{capitalizeWords(customerAddress)}</p>
              <p>{capitalizeWords(customerCity)}</p>
              <p>
                {capitalizeWords(customerCountry)}, {customerPostal}
              </p>
            </div>
          </div>

          <div className="invoice-info">
            <div>
              <h4>Invoice #</h4>
              <p>{invoiceNumber}</p>
            </div>
            <div>
              <h4>Date</h4>
              <p>{new Date(date).toLocaleDateString()}</p>
            </div>
            <div>
              <h4>Invoice Due Date</h4>
              <p>{new Date(dueDate).toLocaleDateString()}</p>
            </div>
            <div className="amount-due">
              <h4>Amount Due</h4>
              <p>${totalAmount.toFixed(2)}</p>
            </div>
          </div>

          <table className="invoice-table">
            <thead>
              <tr>
                <th>Items</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>{capitalizeWords(item.name)}</td>
                  <td>{capitalizeWords(item.description)}</td>
                  <td>{item.quantity}</td>
                  <td>${item.price.toFixed(2)}</td>
                  <td>${(item.quantity * item.price).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="invoice-summary">
            <div>
              <p>Sub-Total:</p>
              <p>${subTotal.toFixed(2)}</p>
            </div>
            <div>
              <p>Tax Rate:</p>
              <p>{taxRate}%</p>
            </div>
            <div>
              <p>Tax:</p>
              <p>${tax.toFixed(2)}</p>
            </div>
            <div className="total-amount">
              <p>Total:</p>
              <p>${totalAmount.toFixed(2)}</p>
            </div>
          </div>

          <div className="invoice-notes">
            <h4>Notes:</h4>
            <p>{notes}</p>
          </div>

          <div className="invoice-footer">
            <p>This invoice was generated with the help of [Your Company].</p>
          </div>
        </div>

        <div className="print-button-container">
          <button onClick={handlePrint}>Print</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Invoice;