import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaEye, FaToggleOn, FaToggleOff, FaTrash } from 'react-icons/fa';
import SearchServices from '../features/SearchServices';
import PaginationComponent from '../features/Pagination';
import BASE_URL from '../config';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ViewAllCustomers.css';
import LoadingScreen from '../features/LoadingScreen';

const ViewAllCustomersLM = () => {
  const [customers, setCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [customersPerPage] = useState(5); // Number of customers per page
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const firstDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      
      const response = await fetch(`${BASE_URL}/customers`);
      if (!response.ok) {
        throw new Error('Failed to fetch customers');
      }
      const data = await response.json();

      // Filter customers created within the current month
      const filteredCustomers = data.data.filter(customer => {
        const createdAt = new Date(customer.createdAtCustomer);
        return createdAt >= firstDayOfMonth && createdAt < firstDayOfNextMonth;
      });
      // filteredCustomers.sort((a, b) => new Date(b.createdAtCustomer) - new Date(a.createdAtCustomer));
      setCustomers(filteredCustomers);
      setLoading(false); // Update loading state once data is fetched
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError(error.message || 'Failed to fetch customers');
      setLoading(false); // Update loading state on error
    }
  };

  const toggleStatus = async (id) => {
    try {
      const customerToUpdate = customers.find(customer => customer.customer_id === id);
      const updatedStatus = customerToUpdate.customer_status === 'Active' ? 'Inactive' : 'Active';

      const response = await axios.put(`${BASE_URL}/customers/update_customer_status/${id}`, {
        customer_status: updatedStatus
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.status !== 200) {
        throw new Error('Failed to update customer status');
      }

      const updatedCustomers = customers.map(customer =>
        customer.customer_id === id ? { ...customer, customer_status: updatedStatus } : customer
      );
      setCustomers(updatedCustomers);
      toast.success(`Customer status updated to ${updatedStatus}`);
    } catch (error) {
      console.error('Error toggling customer status:', error);
      toast.error('Failed to update customer status. Please try again.');
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/customers/delete/${id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        // Remove the deleted customer from the state
        const updatedCustomers = customers.filter(customer => customer.customer_id !== id);
        setCustomers(updatedCustomers);
        toast.success('Customer deleted successfully');
      } else {
        console.error('Failed to delete customer');
        toast.error('Failed to delete customer. Please try again.');
      }
    } catch (error) {
      console.error('Error deleting customer:', error);
      toast.error('Failed to delete customer. Please try again.');
    }
  };

  // Ensure customers are defined before filtering
  const filteredCustomers = customers && customers.filter(customer =>
    (customer.firstName && customer.firstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (customer.lastName && customer.lastName.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (customer.email && customer.email.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Logic to calculate current customers on current page
  const indexOfLastCustomer = currentPage * customersPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
  const currentCustomers = filteredCustomers && filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);

  // Ensure filteredCustomers is defined before calculating totalPages
  const totalPages = filteredCustomers ? Math.ceil(filteredCustomers.length / customersPerPage) : 0;

  // Loading state
  if (loading) {
    return <LoadingScreen />;
  }

  // Error state
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="view-all-customers">
      <h2 className="text-center">All Customers - {new Date().toLocaleString('default', { month: 'long' })} {Date().getFullYear}</h2>

      {/* SearchServices component for search input */}
      <SearchServices searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Customer ID</th>
            <th>First Name</th>
            <th>NIC</th>
            <th>Contact No</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentCustomers && currentCustomers.map(customer => (
            <tr key={customer.customer_id}>
              <td>{customer.customer_id}</td>
              <td>{customer.firstName}</td>
              <td>{customer.nic || '-'}</td>
              <td>{customer.contact_no || '-'}</td>
              <td>{customer.customer_status || '-'}</td>
              <td>{new Date(customer.createdAtCustomer).toLocaleString()}</td>
              <td>
                <Link to={`/update-customer/${customer.customer_id}`} className="action-button">
                  <Button variant="warning" className="mr-2">
                    <FaEdit />
                  </Button>
                </Link>
                <Link to={`/view-customer/${customer.customer_id}`} className="action-button">
                  <Button variant="primary" className="mr-2">
                    <FaEye />
                  </Button>
                </Link>
                <Button
                  variant="info"
                  onClick={() => toggleStatus(customer.customer_id)}
                  className="action-button"
                >
                  {customer.customer_status === 'Active' ? (
                    <>
                      <FaToggleOn />
                    </>
                  ) : (
                    <>
                      <FaToggleOff />
                    </>
                  )}
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(customer.customer_id)}
                  className="action-button mr-2"
                >
                  <FaTrash />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Centered pagination */}
      <Row className="justify-content-center">
        <Col xs="auto">
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ViewAllCustomersLM;