/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Form, Button, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import BASE_URL from '../config';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import debounce from 'lodash.debounce';
import './CreateQuotation.css';

const CreateQuotation = ({ userData }) => {
  const initialInputs = {
    type: '',
    vehicle_no: '',
    desc: '',
    quotationItems: [{ task: '', amount: 0 }],
    quotationItems2: [{ task: '', amount: 0 }],
    totalAmount: 0,
    customer: {
      name: '',
      email: '',
      nic: '',
      contact_no: ''
    },
    staffId: userData.staff_id
  };

  const [inputs, setInputs] = useState({ ...initialInputs });
  const [customers, setCustomers] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/customers`);
      setCustomers(response.data.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
      toast.error('Failed to fetch customers. Please try again.');
    }
  };

  const searchCustomers = useCallback(
    debounce((customerName) => {
      const results = customers.filter(
        (c) =>
          `${c.firstName} ${c.lastName}`.toLowerCase().includes(customerName)
      );
      setSearchResults(results);
    }, 300),
    [customers]
  );

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    if (name === 'amount') {
      const newTasks = [...inputs.quotationItems];
      newTasks[index] = { ...newTasks[index], [name]: parseInt(value) };
      const totalAmount = newTasks.reduce((acc, task) => acc + (task.amount || 0), 0);
      setInputs({ ...inputs, quotationItems: newTasks, totalAmount });
    } else if (name === 'task') {
      const newTasks = [...inputs.quotationItems];
      newTasks[index] = { ...newTasks[index], [name]: value };
      setInputs({ ...inputs, quotationItems: newTasks });
    } else if (name === 'name') {
      const customerName = value.trim().toLowerCase();
      setInputs({ ...inputs, customer: { ...inputs.customer, name: value } });
      if (customerName.length > 0) {
        searchCustomers(customerName);
      } else {
        setSearchResults([]);
      }
    } else if (name.startsWith('customer.')) {
      const fieldName = name.split('.')[1];
      setInputs({ ...inputs, customer: { ...inputs.customer, [fieldName]: value } });
    } else {
      setInputs({ ...inputs, [name]: value });
    }
  };

  const handleSelectCustomer = (selectedCustomer) => {
    setInputs({
      ...inputs,
      customer: {
        name: `${selectedCustomer.firstName} ${selectedCustomer.lastName}`,
        email: selectedCustomer.email,
        nic: selectedCustomer.nic,
        contact_no: selectedCustomer.contact_no
      }
    });
    setSearchResults([]); 
    toast.info('Customer selected.');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Destructuring the inputs
    const { vehicle_no, odoMeter, vehicleModel, desc, paymentType, quotationItems, quotationItems2, customer } = inputs;

    // Validate required fields
    // if (!vehicle_no || !customer.name || !customer.contact_no) {
    //     toast.error('Please fill in all fields.');
    //     return;
    // }

    // Splitting customer name
    const [firstName, lastName] = customer.name.split(' ');

    // Transforming quotationItems and quotationItems2 to arrays of objects
    const taskArray = quotationItems.split('\n').map((line) => {
        const [task, unit, amount] = line.split(',').map((item) => item.trim());
        return { task, unit, amount: parseInt(amount, 10) };
    });

    const extraTaskArray = quotationItems2.split('\n').map((line) => {
        const [extraTask, extraAmount] = line.split(',').map((item) => item.trim());
        return { extraTask, extraAmount: parseInt(extraAmount, 10) };
    });

    // Creating the payload to send in the request
    const serviceData = {
        vehicle_no,
        odoMeter,
        vehicleModel,
        desc,
        paymentType,
        quotationItems: taskArray,
        quotationItems2: extraTaskArray,
        totalAmount: inputs.totalAmount,
        customer: {
            title: customer.title,
            firstName,
            lastName,
            email: customer.email,
            nic: customer.nic,
            contact_no: customer.contact_no
        },
        staffId: inputs.staffId
    };

    try {
        // Sending the POST request
        const response = await axios.post(`${BASE_URL}/service`, serviceData);
        console.log('Service created:', response.data);
        toast.success('Service created successfully.');

        // Resetting the form inputs
        setInputs({ ...initialInputs });

        // Redirecting to view all services page
        navigate('/view-all-services');
    } catch (error) {
        console.error('Error creating service:', error);
        toast.error('Failed to create service. Please try again.');
    }
};

  const handleAddTask = () => {
    setInputs({
      ...inputs,
      quotationItems: [...inputs.quotationItems, { task: '', amount: 0 }]
    });
  };

  const handleRemoveTask = (index) => {
    const newTasks = [...inputs.quotationItems];
    newTasks.splice(index, 1);
    const totalAmount = newTasks.reduce((acc, task) => acc + (task.amount || 0), 0);
    setInputs({ ...inputs, quotationItems: newTasks, totalAmount });
  };

  return (
    <Container fluid className="create-quotation">
      <h2>Create Quotation</h2>
      <br />
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="name">
              <Form.Label>Name:</Form.Label>
              <div className="input-group">
                <Form.Control
                  type="text"
                  name="name"
                  value={inputs.customer.name}
                  onChange={handleChange}
                  required
                />
                {searchResults.length > 0 && (
                  <ListGroup className="autocomplete-results">
                    {searchResults.map((customer, index) => (
                      <ListGroup.Item
                        key={index}
                        action
                        onClick={() => handleSelectCustomer(customer)}
                      >
                        {`${customer.firstName} ${customer.lastName}`}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </div>
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                type="email"
                name="customer.email"
                value={inputs.customer.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="nic">
              <Form.Label>NIC:</Form.Label>
              <Form.Control
                type="text"
                name="customer.nic"
                value={inputs.customer.nic}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="contact_no">
              <Form.Label>Contact No:</Form.Label>
              <Form.Control
                type="text"
                name="customer.contact_no"
                value={inputs.customer.contact_no}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <h4>Quotation Details</h4>
        <hr />
        <Row>
          <Col md={6}>
            <Form.Group controlId="type">
              <Form.Label>Type:</Form.Label>
              <Form.Control
                type="text"
                name="type"
                value={inputs.type}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="vehicle_no">
              <Form.Label>Vehicle No:</Form.Label>
              <Form.Control
                type="text"
                name="vehicle_no"
                value={inputs.vehicle_no}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={12}>
            <Form.Group controlId="desc">
              <Form.Label>Description:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="desc"
                value={inputs.desc}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={12}>
            <Form.Label>Tasks:</Form.Label>
            {inputs.quotationItems.map((task, index) => (
              <div key={index}>
                <br />
                <Row>
                  <Col>
                    <Form.Group controlId={`task-${index}`}>
                      <Form.Label>Task</Form.Label>
                      <Form.Control
                        type="text"
                        value={task.task}
                        onChange={(e) => handleChange(e, index)}
                        name="task"
                        required
                        placeholder="Task"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId={`itemDesc-${index}`}>
                      <Form.Label>Item Desc</Form.Label>
                      <Form.Control
                        type="text"
                        value={task.itemDesc}
                        onChange={(e) => handleChange(e, index)}
                        name="itemDesc"
                        required
                        placeholder="Item Desc"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId={`quantity-${index}`}>
                      <Form.Label>Quantity</Form.Label>
                      <Form.Control
                        type="text"
                        value={task.quantity}
                        onChange={(e) => handleChange(e, index)}
                        name="quantity"
                        required
                        placeholder="Quantity"
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId={`unitPrice-${index}`}>
                      <Form.Label>Unit Price (Rs.)</Form.Label>
                      <Form.Control
                        type="number"
                        value={task.unitPrice}
                        onChange={(e) => handleChange(e, index)}
                        name="unitPrice"
                        required
                        placeholder="Unit Price"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={1}>
                    <Button variant="danger" onClick={() => handleRemoveTask(index)}>
                      X
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <br />
            <Button variant="primary" onClick={handleAddTask}>
              Add Task
            </Button>
          </Col>
        </Row>
        <br />
        {/* <Row>
          <Col md={6}>
            <Form.Group controlId="totalAmount">
              <Form.Label>Total Amount (Rs.)</Form.Label>
              <Form.Control
                type="number"
                name="totalAmount"
                value={inputs.totalAmount}
                readOnly
              />
            </Form.Group>
          </Col>
        </Row> */}
        <br />
        <Row>
        <Button style={{backgroundColor: "#19325f"}} type="submit" className="mt-3">
          Create Quotation
        </Button>
        </Row>
      </Form>
    </Container>
  );
};

export default CreateQuotation;