/* eslint-disable no-unused-vars */
import React, { useRef } from 'react';
import { Button, Modal, Row, Col, Table } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './Invoice.css';

const Invoice = ({ show, handleClose, service }) => {
    const printRef = useRef();

    const addSignatureSection = (pdf, x, y) => {
        pdf.setFontSize(10);
        pdf.text('AUTHORIZED SIGNATURE: ________________________', y, x);
        pdf.text('CUSTOMER SIGNATURE: ________________________', y, x+ 100); // Adjust x value for alignment
    };

    const handlePrint = () => {
        if (!printRef.current) return;

        const printContents = printRef.current.innerHTML;
        const originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        const afterPrint = () => {
            document.body.innerHTML = originalContents;
            window.location.reload();
        };

        window.addEventListener('afterprint', afterPrint);

        window.print();

        window.removeEventListener('afterprint', afterPrint);
    };

    const handleDownload = () => {
        const element = printRef.current;

        html2canvas(element, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const imgWidth = pdfWidth;
            const imgHeight = (canvas.height * pdfWidth) / canvas.width;

            let position = 0;
            const totalPages = Math.ceil(imgHeight / pdfHeight);

            for (let i = 0; i < totalPages; i++) {
                if (i > 0) {
                    pdf.addPage();
                }

                const sliceHeight = i === totalPages - 1
                    ? imgHeight - (totalPages - 1) * pdfHeight
                    : pdfHeight;

                pdf.addImage(imgData, 'PNG', 0, -position, imgWidth, imgHeight);

                addSignatureSection(pdf, 10, pdfHeight - 20); // Add signature section at the bottom of each page

                position += pdfHeight;
            }

            pdf.save(`Invoice_${service.service_id}_${service.vehicle_no}.pdf`);
        });
    };

    if (!service) return null;

    const partsTotal = service.tasks?.reduce((acc, task) => (task.amount ? acc + task.amount : acc), 0) || 0;
    const laborTotal = service.extraTasks?.reduce((acc, extraTask) => (extraTask.extraAmount ? acc + extraTask.extraAmount : acc), 0) || 0;
    const totalAmount = partsTotal + laborTotal;

    const hasTasks = service.tasks && service.tasks.some(task => task.task && task.unit && task.amount);
    const hasExtraTasks = service.extraTasks && service.extraTasks.some(extraTask => extraTask.extraTask && extraTask.extraAmount);

    return (
        <Modal show={show} onHide={handleClose} scrollable>
            <Modal.Header closeButton>
                <Modal.Title>INVOICE DETAILS</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '25px' }}>
                <div ref={printRef} className="invoice-container">
                    <div className="invoice-header">
                        <Row className="align-items-center">
                            <Col>
                                <h1 style={{ fontSize: '34px', color: 'black', marginLeft: '340px' }}>
                                    NEXGEN AUTOCARE
                                </h1>
                            </Col>
                        </Row>
                    </div>
                    <h7>INVOICE</h7>
                    <hr />
                    <Row>
                        <Col className="customer-details">
                            <div className="invoice-details">
                                <h7>CUSTOMER DETAILS</h7>
                                <p className="capitalize" style={{ fontWeight: 'normal', lineHeight: '1', marginTop: "25px" }}>{service.customerName}</p>
                                <p className="capitalize" style={{ fontWeight: 'normal', lineHeight: '1' }}>{service.customerContactNumber}</p>
                                <p className="capitalize" style={{ fontWeight: 'normal', lineHeight: '1' }}>{service.customerEmail}</p>
                                <p className="capitalize" style={{ fontWeight: 'normal', lineHeight: '1' }}>
                                    {new Date().toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                </p>
                            </div>
                        </Col>
                        <Col className="company-details">
                            <div className="invoice-details">
                                <p className="capitalize" style={{ fontWeight: 'normal', lineHeight: '1' }}>236/3, Pragathi Mawatha</p>
                                <p className="capitalize" style={{ fontWeight: 'normal', lineHeight: '1' }}>Katuwana Road Homagama</p>
                                <p className="capitalize" style={{ fontWeight: 'normal', lineHeight: '1' }}>nextgenautocarelk@gmail.com</p>
                                <p className="capitalize" style={{ fontWeight: 'normal', lineHeight: '1' }}>Contact: 0775411060</p>
                            </div>
                        </Col>
                    </Row>
                    <Table bordered style={{borderRadius:"25px", border: "2px solid black"}}>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#eeeeee', fontWeight: "bold", color: "black" }}>INVOICE NO</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{service.service_id}_{service.vehicle_no}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#eeeeee', fontWeight: "bold", color: "black" }}>VEHICLE NO</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{service.vehicle_no}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#eeeeee', fontWeight: "bold", color: "black" }}>PAYMENT TYPE</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{service.paymentType}</td>
                            </tr>
                            <tr style={{border: "2px solid black"}}>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#eeeeee', fontWeight: "bold", color: "black" }}>DATE</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                    {new Date().toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                </td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#eeeeee', fontWeight: "bold", color: "black" }}>ODO METER</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{service.odoMeter}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', backgroundColor: '#eeeeee', fontWeight: "bold", color: "black" }}>VEHICLE MODEL</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{service.vehicleModel}</td>
                            </tr>
                        </tbody>
                    </Table>
                    {hasTasks && (
                        <table className="invoice-table">
                            <thead>
                                <tr style={{ textAlign: 'left', border:"1px" }}>
                                    <th style={{ width: "400px", textAlign: 'left' }}>SPARE PART</th>
                                    <th style={{ width: "100px", textAlign: 'left' }}>QUANTITY</th>
                                    <th style={{ width: "150px", textAlign: 'left' }}>RATE (RS.)</th>
                                    <th style={{ width: "150px", textAlign: 'left' }}>AMOUNT (RS.)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {service.tasks && service.tasks.filter(task => task.task && task.unit && task.amount).map((task, index) => (
                                    <tr key={index}>
                                        <td style={{fontSize: "14px"}}>{task.task}</td>
                                        <td style={{fontSize: "14px"}}>{task.unit}</td>
                                        <td style={{fontSize: "14px"}}>{task.amount / task.unit}.00</td>
                                        <td style={{textAlign: "right", fontSize: "14px"}}>{task.amount}.00</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td style={{ textAlign: 'right', paddingRight: '10px', fontSize: "14px" }}>PARTS TOTAL</td>
                                    <td style={{textAlign: "right", fontSize: "14px"}}>{partsTotal}.00</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                    {hasExtraTasks && (
                        <>
                            <table className="invoice-table">
                                <thead>
                                    <tr style={{ textAlign: 'left' }}>
                                        <th style={{ width: "650px", textAlign: 'left' }}>LABOR DESCRIPTION</th>
                                        <th style={{ width: "150px", textAlign: 'left' }}>CHARGE (RS.)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {service.extraTasks && service.extraTasks.filter(extraTask => extraTask.extraTask && extraTask.extraAmount).map((extraTask, index) => (
                                        <tr key={index}>
                                            <td style={{fontSize: "14px"}}>{extraTask.extraTask}</td>
                                            <td style={{textAlign: "right", fontSize: "14px"}}>{extraTask.extraAmount}.00</td>
                                        </tr>
                                    ))}
                                    <tr style={{fontSize: "14px"}}>
                                        <td style={{ textAlign: 'right', paddingRight: '10px', fontSize: "14px" }}>LABOR TOTAL</td>
                                        <td style={{textAlign: "right", fontSize: "14px"}}>{laborTotal}.00</td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                    )}
                    <table className="invoice-table">
                    <thead>
                                    <tr style={{ textAlign: 'left' }}>
                                        <td style={{ width: "650px", textAlign: 'right', fontSize: "14px" }}> TOTAL AMOUNT PAYABLE (RS.)</td>
                                        <td style={{ width: "150px", textAlign: 'right', fontSize: "14px" }}>{totalAmount}.00</td>
                                    </tr>
                                </thead>
                    </table>  

                    <div className="signature-section" style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', marginTop: '20px' }}>
  <p style={{ margin: 0 }}>
    <span style={{ display: 'inline-block', borderTop: '1px solid black', width: '300px', paddingTop: '5px', fontSize: "14px" }}>AUTHORIZED SIGNATURE</span>
  </p>
  <p style={{ margin: 0 }}>
    <span style={{ display: 'inline-block', borderTop: '1px solid black', width: '300px', paddingTop: '5px', fontSize: "14px" }}>CUSTOMER SIGNATURE</span>
  </p>
</div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="primary" onClick={handleDownload}>
                    Download PDF
                </Button>
                <Button variant="primary" onClick={handlePrint}>
                    <FaPrint /> Print
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Invoice;