/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Form, Button, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import BASE_URL from '../config';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import debounce from 'lodash.debounce';
import './CreateService.css';

const CreateService = ({ userData }) => {
  const initialInputs = {
    vehicle_no: '',
    desc: '',
    odoMeter: '',
    vehicleModel: '',
    paymentType: '',
    tasks: '',
    extraTasks: '',
    totalAmount: 0,
    customer: {
      title: '',
      name: '',
      email: '',
      nic: '',
      contact_no: ''
    },
    staffId: userData.staff_id
  };

  const [inputs, setInputs] = useState({ ...initialInputs });
  const [customers, setCustomers] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/customers`);
      setCustomers(response.data.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
      toast.error('Failed to fetch customers. Please try again.');
    }
  };

  const searchCustomers = useCallback(
    debounce((customerName) => {
      const results = customers.filter(
        (c) =>
          `${c.firstName} ${c.lastName}`.toLowerCase().includes(customerName)
      );
      setSearchResults(results);
    }, 300),
    [customers]
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (['title', 'name', 'email', 'nic', 'contact_no'].includes(name)) {
      setInputs((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          [name]: value
        }
      }));
      if (name === 'name') {
        const customerName = value.trim().toLowerCase();
        if (customerName.length > 0) {
          searchCustomers(customerName);
        } else {
          setSearchResults([]);
        }
      }
    } else {
      setInputs((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSelectCustomer = (selectedCustomer) => {
    setInputs((prevState) => ({
      ...prevState,
      customer: {
        title: selectedCustomer.title,
        name: `${selectedCustomer.firstName} ${selectedCustomer.lastName}`,
        email: selectedCustomer.email,
        nic: selectedCustomer.nic,
        contact_no: selectedCustomer.contact_no
      }
    }));
    setSearchResults([]);
    toast.info('Customer selected.');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Destructuring the inputs
    const { vehicle_no, odoMeter, vehicleModel, desc, paymentType, tasks, extraTasks, customer } = inputs;

    // Validate required fields
    // if (!vehicle_no || !customer.name || !customer.contact_no) {
    //     toast.error('Please fill in all fields.');
    //     return;
    // }

    // Splitting customer name
    const [firstName, lastName] = customer.name.split(' ');

    // Transforming tasks and extraTasks to arrays of objects
    const taskArray = tasks.split('\n').map((line) => {
        const [task, unit, amount] = line.split(',').map((item) => item.trim());
        return { task, unit, amount: parseInt(amount, 10) };
    });

    const extraTaskArray = extraTasks.split('\n').map((line) => {
        const [extraTask, extraAmount] = line.split(',').map((item) => item.trim());
        return { extraTask, extraAmount: parseInt(extraAmount, 10) };
    });

    // Creating the payload to send in the request
    const serviceData = {
        vehicle_no,
        odoMeter,
        vehicleModel,
        desc,
        paymentType,
        tasks: taskArray,
        extraTasks: extraTaskArray,
        totalAmount: inputs.totalAmount,
        customer: {
            title: customer.title,
            firstName,
            lastName,
            email: customer.email,
            nic: customer.nic,
            contact_no: customer.contact_no
        },
        staffId: inputs.staffId
    };

    try {
        // Sending the POST request
        const response = await axios.post(`${BASE_URL}/service`, serviceData);
        console.log('Service created:', response.data);
        toast.success('Service created successfully.');

        // Resetting the form inputs
        setInputs({ ...initialInputs });

        // Redirecting to view all services page
        navigate('/view-all-services');
    } catch (error) {
        console.error('Error creating service:', error);
        toast.error('Failed to create service. Please try again.');
    }
};

  return (
    <Container fluid className="create-service">
      <Row className="justify-content-md-center">
        <Col md={8}>
          <h1 className="text-center my-4">Create Service</h1>
          <Form onSubmit={handleSubmit}>
            <Row>
            <Col>
            <Form.Group controlId="title">
  <Form.Label>Title</Form.Label>
  <Form.Select
    name="title"
    value={inputs.customer.title || ''}
    onChange={handleChange}
  >
    <option value="Mr.">Mr.</option>
      <option value="Ms.">Ms.</option>
      <option value="Mrs.">Mrs.</option>
      <option value="Dr.">Dr.</option>
      <option value="Prof.">Prof.</option>
      <option value=" ">None</option>
  </Form.Select>
</Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="name">
                  <Form.Label>Customer Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={inputs.customer.name}
                    onChange={handleChange}
                    required
                  />
                  {searchResults.length > 0 && (
                    <ListGroup className="mt-2">
                      {searchResults.map((customer) => (
                        <ListGroup.Item
                          key={customer._id}
                          action
                          onClick={() => handleSelectCustomer(customer)}
                        >
                          {customer.firstName} {customer.lastName}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={inputs.customer.email}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Group controlId="nic">
                  <Form.Label>NIC</Form.Label>
                  <Form.Control
                    type="text"
                    name="nic"
                    value={inputs.customer.nic}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="contact_no">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="contact_no"
                    value={inputs.customer.contact_no}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Form.Group controlId="vehicle_no">
              <Form.Label>Vehicle Number</Form.Label>
              <Form.Control
                type="text"
                name="vehicle_no"
                value={inputs.vehicle_no}
                onChange={handleChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="vehicleModel">
              <Form.Label>Vehicle Model</Form.Label>
              <Form.Control
                type="text"
                name="vehicleModel"
                value={inputs.vehicleModel}
                onChange={handleChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="odoMeter">
              <Form.Label>ODO Meter</Form.Label>
              <Form.Control
                type="text"
                name="odoMeter"
                value={inputs.odoMeter}
                onChange={handleChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="desc">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="desc"
                value={inputs.desc}
                onChange={handleChange}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="paymentType">
  <Form.Label>Payment Type</Form.Label>
  <Form.Control
    as="select"
    name="paymentType"
    value={inputs.paymentType}
    onChange={handleChange}
  >
    <option value="">Select Payment Type</option>
    <option value="cash">Cash</option>
    <option value="credit">Credit</option>
  </Form.Control>
</Form.Group>
<br />
            <Form.Group controlId="tasks">
              <Form.Label>Spare Part (Format: Spare Part, Quantity, Amount per line)</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="tasks"
                value={inputs.tasks}
                onChange={handleChange}
                placeholder="Vehicle Part 1, Quantity 1, Amount 1&#10;Vehicle Part 2, Quantity 2, Amount 2"
              />
            </Form.Group>
            <br />
            <Form.Group controlId="extraTasks">
              <Form.Label>Labor (Format: Labor, Amount per line)</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="extraTasks"
                value={inputs.extraTasks}
                onChange={handleChange}
                placeholder="Labor 1, Amount 1&#10;Labor 2, Amount 2"
              />
            </Form.Group>
            <Row>
              <Button style={{ backgroundColor: '#19325f' }} type="submit" className="mt-3">
                Create Service
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateService;