/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import './UpdateQuotation.css';
import BASE_URL from '../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdateQuotation = ({ userData }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [quotation, setQuotation] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    // Fetch quotation details by ID
    fetchQuotationDetails();
  }, []);

  const fetchQuotationDetails = async () => {
    try {
      const response = await fetch(`${BASE_URL}/quotation/${id}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setQuotation(data);
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      console.error('Error fetching quotation:', error);
      setLoading(false); // Ensure loading state is handled in case of errors
    }
  };

  const initialFormData = {
    type: '',
    vehicle_no: '',
    desc: '',
    quotationItems: [],
    staffId: userData.staff_id
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (quotation) {
      setFormData({
        type: quotation.type || '',
        vehicle_no: quotation.vehicle_no || '',
        desc: quotation.desc || '',
        quotationItems: quotation.quotationItems ? quotation.quotationItems.map(task => ({ ...task })) : [],
        staffId: userData.staff_id
      });
    }
  }, [quotation]);

  const handleTaskChange = (index, event) => {
    const newTasks = [...formData.quotationItems];
    newTasks[index][event.target.name] = event.target.value;
    setFormData({
      ...formData,
      quotationItems: newTasks,
    });
  };

  const handleAddTask = () => {
    setFormData({
      ...formData,
      quotationItems: [...formData.quotationItems, { task: '', amount: 0 }],
    });
  };

  const handleRemoveTask = (index) => {
    const newTasks = [...formData.quotationItems];
    newTasks.splice(index, 1);
    setFormData({
      ...formData,
      quotationItems: newTasks,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Implement update logic here, using quotation.id and updated formData
    console.log(`Updated quotation ${id} with:`, formData);

    // Replace with actual API call to update quotation
    fetch(`${BASE_URL}/quotation/update/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => response.json())
      .then(updatedQuotation => {
        console.log('Quotation updated successfully:', updatedQuotation);
        toast.success('Quotation updated successfully');
        navigate(-1); // Navigate back one step in history
      })
      .catch(error => {
        console.error('Error updating quotation:', error);
        toast.error('Failed to update quotation. Please try again.');
      });
  };

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="update-quotation">
      <h2>Update Quotation</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Type:
          <input
            type="text"
            name="type"
            value={formData.type}
            onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            required
          />
        </label>
        <br />
        <label>
          Vehicle Number:
          <input
            type="text"
            name="vehicle_no"
            value={formData.vehicle_no}
            onChange={(e) => setFormData({ ...formData, vehicle_no: e.target.value })}
            required
          />
        </label>
        <br />
        <label>
          Description:
          <input
            type="text"
            name="desc"
            value={formData.desc}
            onChange={(e) => setFormData({ ...formData, desc: e.target.value })}
            required
          />
        </label>
        <br />
        <fieldset>
          <legend>Tasks:</legend>
          {formData.quotationItems.map((task, index) => (
            <div key={index} className="task-item">
              <Row>
                {/* <Col>
              <label>
                Task:
                <input
                  type="text"
                  name="task"
                  value={task.task}
                  onChange={(e) => handleTaskChange(index, e)}
                  required
                />
              </label>
              </Col>
              <br /> */}
              <Col md={4}>
              <label>
              itemDesc:
                <input
                  type="text"
                  name="itemDesc"
                  value={task.itemDesc}
                  onChange={(e) => handleTaskChange(index, e)}
                  required
                />
              </label>
              </Col>
              <br />
              <Col md={3}>
              <label>
              quantity:
                <input
                  type="text"
                  name="quantity"
                  value={task.quantity}
                  onChange={(e) => handleTaskChange(index, e)}
                  required
                />
              </label>
              </Col>
              <br />
              <Col md={3}>
              <label>
              unitPrice:
                <input
                  type="number"
                  name="unitPrice"
                  value={task.unitPrice}
                  onChange={(e) => handleTaskChange(index, e)}
                  required
                />
              </label>
              </Col>
              <Col>
              <Button variant="danger" onClick={() => handleRemoveTask(index)}>Remove</Button>
              </Col>
              </Row>
            </div>
          ))}
          <Button type="button" onClick={handleAddTask}>Add Task</Button>
        </fieldset>
        <Row>
          <Button style={{backgroundColor: "#19325f"}} type="submit" className="mt-3">Update Quotation</Button>
        </Row>
      </form>
    </div>
  );
};

export default UpdateQuotation;