import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row, Container, Card, Table } from 'react-bootstrap';
import './ViewStaff.css';
import BASE_URL from '../config';

const ViewStaff = () => {
  const { id } = useParams();
  const [staff, setStaff] = useState(null);

  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await fetch(`${BASE_URL}/staff/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch staff');
        }
        const data = await response.json();
        setStaff(data); // Set the staff data directly from API response
      } catch (error) {
        console.error('Error fetching staff:', error);
      }
    };

    fetchStaff();
  }, [id]);

  return (
    <Container className="view-staff-container">
      <h2 className="page-title">Staff Details</h2>
      {staff ? (
        <Row>
          <Col md={12}>
            <Card className="staff-details">
              <Card.Body>
                <Table>
                  <tbody>
                    <tr>
                      <td><strong>Staff ID</strong></td>
                      <td>{staff.staff_id}</td>
                    </tr>
                    <tr>
                      <td><strong>First Name</strong></td>
                      <td>{staff.firstName}</td>
                    </tr>
                    <tr>
                      <td><strong>Last Name</strong></td>
                      <td>{staff.lastName}</td>
                    </tr>
                    <tr>
                      <td><strong>Email</strong></td>
                      <td>{staff.email}</td>
                    </tr>
                    <tr>
                      <td><strong>NIC</strong></td>
                      <td>{staff.nic || '-'}</td>
                    </tr>
                    <tr>
                      <td><strong>Contact No</strong></td>
                      <td>{staff.contact_no || '-'}</td>
                    </tr>
                    <tr>
                      <td><strong>Status</strong></td>
                      <td>{staff.staff_status || '-'}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <p className="loading-message">Loading...</p>
      )}
    </Container>
  );
};

export default ViewStaff;